import React, { useEffect, useState } from "react"
import "./job-list.scss"
import { Link } from "gatsby-plugin-react-intl"


export const JobListComponent = props => {
  const { jobs } = props;
  return (
    <div className="job-list">

    {jobs.map((job, index) => (
      <Link to={`/careers/${job.slug}`} title={job.title}>

      <div key={index} className="job-post-card">
        <img src={job.image.file.url} alt={job.image.title}></img>
        <div className="title">
          <span>{job.title}</span>
        </div>
      </div>
      </Link>
    ))}

    </div>
  )
}

