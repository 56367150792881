import Layout from "../components/layout"
import * as React from "react"
import { Helmet } from "react-helmet"
import { FormattedMessage, injectIntl } from "gatsby-plugin-react-intl"
import { JobListComponent } from "../components/blog-page/job-list/job-list"
import { graphql } from "gatsby"

const CareersPage = ({ data, pageContext }) => {
  return (
    <Layout>
      <Helmet>
        <meta charSet="utf-8" />
        <title>KalBytes - Careers</title>
        <link rel="canonical" href="https://www.kalbytes.de" />
      </Helmet>

      <div className="blog-page page">
        <h1>Open Positions</h1>
        <JobListComponent
          jobs={data.allContentfulJobBlog.nodes}
        ></JobListComponent>
      </div>

      {/* <div className="container page flex-center-align">
        <h2>
          <FormattedMessage id="pages.careers.noAvailable" />
        </h2>
      </div> */}
    </Layout>
  )
}

export const pageQuery = graphql`
  query BlogQuery($locale: String) {
    allContentfulJobBlog(filter: { node_locale: { eq: $locale } }) {
      nodes {
        node_locale
        title
        slug
        image {
          file {
            url
          }
          title
        }

        body {
          raw
        }
      }
    }
  }
`

export default CareersPage
